<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Absensi Rapat Komite Komisaris",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Absensi Rapat Komite Komisaris",
      items: [
        {
          text: "Administrasi Rapat",
          href: "/",
        },
        {
          text: "Absensi Rapat Komite Komisaris",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      table_data: [],
      search: "",
      role_slug: Cookies.get("session_role_slug"),
      access_token_brigate: Cookies.get("access_token_brigate"),
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;
      if (Cookies.get("id_user") != null) {
        axios
          .get(
            process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/administrasirapat/absensirapatkomisaris?role_slug=" +
            self.role_slug +
            "&search=" +
            self.search + "&id_user=" +
            Cookies.get("id_user")
          )
          .then((response) => {
            var response_data = response.data;
            if (response_data.code == 200) {
              self.table_data = response_data.list_data.data;
              self.loadingTable = false;
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.message,
              });
            }
          });
      }
    },
    eksporHandle() {
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_BACKEND_URL + "/administrasi/absensirapat/ekspor?tipe_rapat=komite_komisaris&search=" + this.search;
      link.setAttribute('target', '_blank');
      link.click();
    },
    hapusData(id) {
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/administrasirapat/absensi/delete",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman administrasi rapat komisaris segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="text-end">
                  <button
                    @click="eksporHandle"
                    class="btn btn-success m-1 rounded-0"
                  ><i class="fas fa-file-excel"></i> Ekspor Agenda Rapat</button>
                  <router-link
                    :to="{ name: 'add-rapat-komisaris' }"
                    class="btn btn-info m-1 rounded-0"
                  ><i class="fa fa-plus"></i> Tambah Agenda Rapat</router-link>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3 mt-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      placeholder="Cari Data..."
                      v-model="search"
                      @input="getDataTable()"
                    />
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr>
                        <th
                          class="text-center text-white"
                          style="width: 50px;background-color: #132d4a"
                        >No</th>
                        <th
                          class="text-center text-white"
                          style="width: 150px;background-color: #132d4a"
                        >Tanggal</th>
                        <th
                          class="text-center text-white"
                          style="width: 150px;background-color: #132d4a"
                        >Komite</th>
                        <th
                          class="text-center text-white"
                          style="width: 150px;background-color: #132d4a"
                        >Agenda Rapat</th>
                        <th
                          class="text-center text-white"
                          style="width: 150px;background-color: #132d4a"
                        >Divisi Pemateri</th>
                        <th
                          class="text-center text-white"
                          style="width: 175px;background-color: #132d4a"
                        >Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="5">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="table_data == ''">
                        <td
                          class="text-center"
                          colspan="5"
                        >
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in table_data"
                        :key="key_data"
                      >
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td class="text-center">
                          {{ row_data.ar_tanggal }}
                        </td>
                        <td>
                          {{ row_data.ar_mk_nama }}
                        </td>
                        <td>
                          <ol v-if="row_data.agenda_rapat">
                            <li
                              v-for="(item, key_items) in row_data.agenda_rapat.split(';')"
                              :key="item"
                            >
                              {{ key_items + 1 }}. {{ item }}
                            </li>
                          </ol>
                        </td>
                        <td>
                          <ol v-if="row_data.divisi_pemateri">
                            <li
                              v-for="(item, key_items) in row_data.divisi_pemateri.split(';')"
                              :key="item"
                            >
                              {{ key_items + 1 }}. {{ JSON.parse(item).join(", ") }}
                            </li>
                          </ol>
                        </td>
                        <td class="text-center">
                          <div
                            class="btn-group"
                            role="group"
                          >
                            <router-link
                              :to="{
                                name: 'detail-rapat-komisaris',
                                params: { id: row_data.ar_id },
                              }"
                              class="btn btn-info btn-sm"
                            ><i class="fa fa-info"></i> Detail</router-link>

                            <router-link
                              :to="{
                                name: 'edit-rapat-komisaris',
                                params: { id: row_data.ar_id },
                              }"
                              class="btn btn-primary btn-sm"
                            ><i class="fa fa-edit"></i> Edit</router-link>
                            <button
                              v-if="role_slug == 'super_admin'"
                              type="button"
                              class="btn btn-danger btn-sm"
                              v-on:click="hapusData(row_data.ar_id)"
                            >
                              <i class="fa fa-trash"></i> Hapus
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
